import "./App.css";
import Footer from "./sections/Footer";
import Logo from "./Logo";
import Navbar from "./sections/Navbar";
import Material from "./sections/Material";
// import Applications from "./sections/Applications";
import Machine from "./sections/Machine";
import { SmoothScroll } from "./common/SmoothScroll";
import Order from "./sections/Order";
// import "bootstrap/dist/css/bootstrap.min.css";
// import $ from "jquery";
// import Popper from "popper.js";
// import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Logo />
        <p
          className="block"
          style={{
            color: "white",
            fontSize: "2rem",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "2rem",
          }}
        >
          Disrupting HBM with atomic-scale technology
        </p>
        <div className="row">
          <button className="btn btn-transparent page-scroll mx-2" onClick={(e) => SmoothScroll(e, "material")}>
            Explore
          </button>
        </div>
      </header>
      <Navbar />
      <section className="bg-one about section " id="material">
        <Material />
      </section>
      {/* <section className="bg-one portfolio section  section-bg overly" id="applications">
        <Applications />
      </section> */}
      {/* <section id="machine" className="parallax-section section section-bg overly">
        <Machine />
      </section> */}
      <section id="order" className="parallax-section section section-bg overly">
        <Order />
      </section>
      <Footer />
    </div>
  );
}

export default App;
